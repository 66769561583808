
	import { Component, InjectReactive, Mixins, Prop } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import BaseModal from '@pixcap/ui-library/components/BaseModal.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import AuthenticationModalMixin from '@pixcap/ui-core/components/account/AuthenticationModalMixin';
	import { CONTEXT_PROVIDER_TYPES } from '@pixcap/ui-core/models/widgets/contextProvider.interface';
	import IconSparkle from '@pixcap/ui-library/components/Icons/IconSparkle.vue';
	import IconPaintBrush from '@pixcap/ui-library/components/Icons/IconPaintBrush.vue';
	import IconFiles from '@pixcap/ui-library/components/Icons/IconFiles.vue';

	import LibraryItemListLoader from '@/components/pixcap-library/shared/LibraryItemListLoader.vue';
	import CommunityItem from '@/components/community/CommunityItem.vue';
	import CommunityAuthorAvatar from '@/components/community/CommunityAuthorAvatar.vue';
	import { actionsWrapper as CommunityActions } from '@/store/community/wrapper';
	import { COMMUNITY_RESOURCE_TYPE, ICommunityState, NAMESPACE as COMMUNITY_NAMESPACE } from '@/models/store/community.interface';
	import { actionsWrapper as ProjectActions } from '@/store/project/wrapper';
	import { checkScrollToButtom } from '@/utils/scrollUtils';
	import CommunityItemDetailsThumbnails from '@/components/community/CommunityItemDetailsThumbnails.vue';
	import CommunityShareAction from '@/components/community/actions/CommunityShareAction.vue';
	import CommunityLoveAction from '@/components/community/actions/CommunityLoveAction.vue';
	import MasonryWall from '@/components/pixcap-library/shared/MasonryWall.vue';

	@Component({
		name: 'CommunityAISceneItemDetails',
		components: {
			MasonryWall,
			CommunityLoveAction,
			CommunityShareAction,
			IconFiles,
			IconPaintBrush,
			IconSparkle,
			CommunityItemDetailsThumbnails,
			CommunityAuthorAvatar,
			CommunityItem,
			HeadingText,
			BodyText,
			CaptionText,
			BaseModal,
			Button,
			LibraryItemListLoader,
		},
		computed: {
			...mapState(COMMUNITY_NAMESPACE, {
				isFetchingCommunityRelatedModels: (state: ICommunityState) => state.isFetchingCommunityRelatedModels,
				communityItem: (state: ICommunityState) => state.communityItem,
				communityRelatedItemList: (state: ICommunityState) => state.communityRelatedItemList,
				totalPages: (state: ICommunityState) => state.communityRelatedItemList.totalPages,
				currentPage: (state: ICommunityState) => state.communityRelatedItemList.currentPage,
			}),
		},
	})
	export default class CommunityAISceneItemDetails extends Mixins(AuthenticationModalMixin) {
		@Prop({ type: Boolean, default: false }) openInModal: boolean;

		isFetchingCommunityRelatedModels: ICommunityState['isFetchingCommunityRelatedModels'];
		communityItem: ICommunityState['communityItem'];
		communityRelatedItemList: ICommunityState['communityRelatedItemList'];
		totalPages: ICommunityState['communityRelatedItemList']['totalPages'];
		currentPage: ICommunityState['communityRelatedItemList']['currentPage'];
		scrollCallbackName = 'communityRelatedItemList.scrollCallbackName';

		_handleScreenSize;
		isDrawingColumn: boolean = true;
		minColumn: number | undefined = 0;

		@InjectReactive() isDesktopScreen: boolean;

		COMMUNITY_RESOURCE_TYPE = COMMUNITY_RESOURCE_TYPE;

		$refs: {
			refElementsAiCommunityItemDetail: HTMLElement;
		};

		get eventProviderType() {
			return this.openInModal ? CONTEXT_PROVIDER_TYPES.CUSTOMIZER_AI_COMMUNITY_ITEM_DETAl_SCROLL : CONTEXT_PROVIDER_TYPES.DASHBOARD_LAYOUT;
		}

		get itemDetails() {
			if (!this.communityItem) return null;
			return {
				author: {
					avatar: this.communityItem.userAvatar || '',
					name: this.communityItem.userName,
				},
				jobId: this.communityItem.jobId,
				likeCount: this.communityItem.likes,
				isLiked: this.communityItem.liked,
				originalSceneUrl: this.communityItem.original || '',
				thumbnails: this.communityItem.images.map((i) => ({
					thumbnailUrl: i.fileUrl,
					alt: i.fileId,
					fileId: i.fileId,
				})),
				prompt: this.communityItem.prompt || '',
			};
		}

		get canFetchMore() {
			return this.communityItem && !this.isFetchingCommunityRelatedModels && this.currentPage < this.totalPages;
		}

		get relatedModels() {
			if (!this.communityRelatedItemList.listOfItems.length) return [];
			return this.communityRelatedItemList.listOfItems;
		}

		mounted() {
			const { contextProvider } = this.$pixcap.$widgets;
			contextProvider.registerProviderInjection(this.eventProviderType, {
				callback: this.handleFetchNext.bind(this),
				callbackName: this.scrollCallbackName,
			});
			this.fetchAiCommunityItems(true, this.communityItem.jobId);
			this._handleScreenSize = this.handleScreenSize.bind(this);
			window.addEventListener('resize', this._handleScreenSize);
			this.handleScreenSize();
		}

		beforeDestroy() {
			const { contextProvider } = this.$pixcap.$widgets;
			contextProvider.deregisterProviderInjection(this.eventProviderType, this.scrollCallbackName);
			window.removeEventListener('resize', this._handleScreenSize);
			if (this.openInModal) {
				contextProvider.resetProviderInjections(this.eventProviderType);
			}
		}

		async handleFetchNext() {
			if (this.canFetchMore) {
				await this.fetchAiCommunityItems(false, this.communityItem.jobId);
			}
		}

		handleRemixScene() {
			ProjectActions.createDesignProject(this.$store, this.$router, { templateId: this.communityItem.templateId }, 'Remix in Mix and Match', {
				'Item Slug': this.communityItem.slug,
				'Item Type': this.communityItem.exportType,
			});
		}

		handleScreenSize() {
			if (typeof window !== 'undefined') {
				if (window.innerWidth <= 520) {
					this.minColumn = 2;
				} else if (window.innerWidth > 520 && window.innerWidth <= 743) {
					this.minColumn = 2;
				} else if (window.innerWidth <= 1024) {
					this.minColumn = 4;
				} else if (window.innerWidth >= 1025 && window.innerWidth < 1440) {
					this.minColumn = 4;
				} else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
					this.minColumn = 5;
				} else this.minColumn = 5;
			}
		}

		async fetchAiCommunityItems(refresh: boolean, ignoredId: string) {
			await CommunityActions.getListCommunityItems(this.$store, {
				page: this.communityRelatedItemList.currentPage,
				pageSize: this.communityRelatedItemList.pageSize,
				search: this.communityItem.prompt,
				exportType: COMMUNITY_RESOURCE_TYPE.AI_SCENES,
				isFetchRelated: true,
				refresh,
				ignoreIds: [ignoredId],
			});
		}

		handleScroll() {
			if (!this.openInModal) return;
			const $el = this.$refs.refElementsAiCommunityItemDetail;
			const scrollToBottom = checkScrollToButtom($el, 400);
			if (scrollToBottom) this.emitLibraryScrollInjections();
		}

		handleScrollBack() {
			if (process.client && this.$refs.refElementsAiCommunityItemDetail) {
				const $el = this.$refs.refElementsAiCommunityItemDetail as HTMLElement;
				$el.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}

		emitLibraryScrollInjections() {
			const { contextProvider } = this.$pixcap.$widgets;
			contextProvider.emitProviderInjections(this.eventProviderType);
		}
	}
