
	import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import IconForwardArrow from '@pixcap/ui-library/components/Icons/IconForwardArrow.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import IconLargeArrowRight from '@pixcap/ui-library/components/Icons/IconLargeArrowRight.vue';
	import { IAssetsLibraryState, LIBRARY_TAB, NAMESPACE as LIBRARY_NAMESPACE } from '@/models/store/pixcapassets.interface';
	import { mapState } from 'vuex';
	import { mutationsWrapper as LibraryMutations } from '@/store/pixcapassets/wrapper';

	@Component({
		name: 'ExploreItemTypeBanner',
		components: { IconLargeArrowRight, Button, IconForwardArrow, BodyText, HeadingText },
		computed: {
			...mapState(LIBRARY_NAMESPACE, {
				activeExplorerTab: (state: IAssetsLibraryState) => state.activeExplorerTab,
			}),
		},
	})
	export default class ExploreItemTypeBanner extends Vue {
		activeExplorerTab: IAssetsLibraryState['activeExplorerTab'];

		@Prop() type: LIBRARY_TAB | string;

		@Inject() handleScrollBack: (isSmoothBehavior: boolean) => void;

		componentKey = 0;

		@Watch('type')
		handleRerender() {
			this.componentKey += 1;
		}

		get thumbnailUrl() {
			switch (this.type) {
				case LIBRARY_TAB.THREE_D_CHARACTERS:
					return `${process.env.ROOT_URL}/cdn/explore/banner/explore-item-type-banner/characters.mp4`;
				case LIBRARY_TAB.MOCKUPS:
					return `${process.env.ROOT_URL}/cdn/explore/banner/explore-item-type-banner/mockups.mp4`;
				case LIBRARY_TAB.THREE_D_PACKS:
				default:
					return `${process.env.ROOT_URL}/cdn/explore/banner/explore-item-type-banner/icons.mp4`;
			}
		}

		get bannerTitle() {
			switch (this.type) {
				case LIBRARY_TAB.THREE_D_CHARACTERS:
					return this.$t('common_elements.explore_item_type_banner.characters.title');
				case LIBRARY_TAB.MOCKUPS:
					return this.$t('common_elements.explore_item_type_banner.mockups.title');
				case LIBRARY_TAB.THREE_D_PACKS:
				default:
					return this.$t('common_elements.explore_item_type_banner.icons.title');
			}
		}

		get bannerDescription() {
			switch (this.type) {
				case LIBRARY_TAB.THREE_D_CHARACTERS:
					return this.$t('common_elements.explore_item_type_banner.characters.description');
				case LIBRARY_TAB.MOCKUPS:
					return this.$t('common_elements.explore_item_type_banner.mockups.description');
				case LIBRARY_TAB.THREE_D_PACKS:
				default:
					return this.$t('common_elements.explore_item_type_banner.icons.description');
			}
		}

		get ctaTitle() {
			switch (this.type) {
				case LIBRARY_TAB.THREE_D_CHARACTERS:
					return this.$t('common_elements.explore_item_type_banner.characters.btn_text');
				case LIBRARY_TAB.MOCKUPS:
					return this.$t('common_elements.explore_item_type_banner.mockups.btn_text');
				case LIBRARY_TAB.THREE_D_PACKS:
				default:
					return this.$t('common_elements.explore_item_type_banner.icons.btn_text');
			}
		}

		get shouldScrollUp() {
			return this.$route.name === 'LibraryPage' && this.type === this.activeExplorerTab;
		}

		handleRedirect() {
			LibraryMutations.setSelectedLibraryItemSlug(this.$store, null);
			LibraryMutations.setSelectedLibraryTemplateSlug(this.$store, null);
			switch (this.type) {
				case LIBRARY_TAB.THREE_D_CHARACTERS:
					if (this.shouldScrollUp) this.handleScrollBack(true);
					else this.$router.push({ name: 'LibraryPage', params: { tab: LIBRARY_TAB.THREE_D_CHARACTERS } });
					break;
				case LIBRARY_TAB.MOCKUPS:
					if (this.shouldScrollUp) this.handleScrollBack(true);
					else this.$router.push({ name: 'LibraryPage', params: { tab: LIBRARY_TAB.MOCKUPS } });
					break;
				case LIBRARY_TAB.THREE_D_PACKS:
				default:
					if (this.shouldScrollUp) this.handleScrollBack(true);
					else this.$router.push({ name: 'LibraryPage', params: { tab: LIBRARY_TAB.THREE_D_PACKS } });
					break;
			}
		}
	}
